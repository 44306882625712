/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import React from 'react';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { Styled, jsx, Box, IconButton } from 'theme-ui';
import { Minus, Plus } from 'react-feather';
import { useDropdown } from './reflex-src/use-dropdown';
import { NavMenuLink as ReflexNavMenuLink } from './reflex-navlink';

export const NavMenuItem = ({
  title,
  link,
  submenuItems,
  kind,
  dropdown,
  trigger,
  vertical,
  expand,
  internalPageRoute,
  depth = 0,
  ...props
}) => {
  const [dropdownRef, isOpen, open, close] = useDropdown();
  const isMobile = useBreakpointIndex() <= 1;
  if (!title) return null;

  const usingHover = trigger === 'hover';

  props = {
    ...props,
    ...(usingHover &&
      !isMobile && {
        onMouseEnter: open,
        onMouseLeave: close,
      }),
    ...(isMobile && { onClick: isOpen ? close : open }),
  };

  return (
    <Styled.li
      ref={dropdownRef}
      sx={{
        mt: [vertical || !dropdown ? 2 : 0, null, depth === 1 ? 2 : 0],
        mb: 0,
        py: 0,
        borderBottom: [depth === 0 && !vertical && dropdown ? 1 : 0, null, 0],
        borderColor: 'muted',
        position: 'relative',
        variant: 'variants.navLink',
      }}
      {...props}
    >
      <ReflexNavMenuLink
        title={title}
        link={link}
        kind={kind}
        route={internalPageRoute}
        cursor="pointer"
        dropdown={dropdown}
        depth={depth}
        isOpen={isOpen}
        {...(dropdown && !usingHover && { onClick: isOpen ? close : open })}
      />
      {expand && submenuItems?.length && (
        <React.Fragment>
          {dropdown && (
            <IconButton
              // size={60}
              onClick={() => (isOpen ? close() : open())}
              sx={{
                display: ['block', null, 'none'],
                position: 'absolute',
                top: 1,
                right: 4,
                py: [0],
                // height: '12px',
                // width: '12px',
              }}
            >
              {isOpen ? <Minus /> : <Plus />}
            </IconButton>
          )}
          <NavMenuSubNav
            submenuItems={submenuItems}
            dropdown={dropdown}
            depth={depth}
            isOpen={isOpen || !dropdown}
          />
        </React.Fragment>
      )}
    </Styled.li>
  );
};

export const NavMenuSubNav = ({ submenuItems, dropdown, isOpen, ...props }) => {
  if (!submenuItems?.length) return null;

  //   const [block] = items;
  //   const isBlock = items[0].type === 'block';

  const item = ( // isBlock ? (
    //     <Block src={block.value} {...props} />
    //   ) : (
    <Styled.ul sx={{ listStyle: 'none', p: 0, m: 0 }}>
      {submenuItems.map((submenuItem, index) => (
        <NavMenuItem key={index} depth={1} dropdown={false} {...submenuItem} />
      ))}
    </Styled.ul>
  );

  return dropdown ? (
    <Box
      sx={{
        position: [null, null, 'absolute'],
        maxHeight: [isOpen ? '100vh' : 0, null, 'none'],
        boxShadow: [null, null, 'xl'],
        bg: ['navbggrey', 'navbggrey', 'background'],
        borderRadius: [null, null, 'lg'],
        overflow: 'hidden',
        opacity: [1, null, isOpen ? 1 : 0],
        visibility: ['visible', null, isOpen ? 'visible' : 'hidden'],
        transition: 'all .15s ease-in',
        transform: ['none', null, 'translateX(-50%)'],
        left: '20%',
        top: isOpen ? '100%' : '130%',
        zIndex: 500,
        border: [0, null, '1px solid'],
        borderColor: ['border', null, 'border'],

        ul: {
          py: 0,
          minWidth: '240px',
          my: 0,
          ml: 0,

          li: {
            mt: 0,
            a: {
              py: 1,
              px: 4,
              fontWeight: 'normal',

              '&:hover': {
                bg: 'navbggrey',
              },
            },
          },
        },
      }}
    >
      {item}
    </Box>
  ) : (
    item
  );
};
