/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx, Styled, Flex, Box, Heading, IconButton } from 'theme-ui';
import { Facebook, Instagram, Twitter, Youtube } from 'react-feather';
import { useSiteMetadata } from '../utils';
import { ReflexLink as Link } from './header/navbar/reflex-link';

const LegalFooterLi = ({ ...props }) => (
  <li
    {...props}
    sx={{
      my: 1,
      mx: 1,
    }}
  />
);

const FooterMainItem = ({ ...props }) => (
  <Box
    {...props}
    sx={{
      p: 2,
      minWidth: '250px',
      flex: '1',
    }}
  />
);

const FooterHeading = ({ ...props }) => (
  <Heading
    as="h3"
    {...props}
    sx={{
      fontWeight: '200',
      color: 'secondary',
      pt: 3,
    }}
  />
);

const SiteFooter = () => {
  const metaData = useSiteMetadata();
  const { address } = metaData.sanityData;

  return (
    <footer
      sx={{
        width: '100%',
        bg: 'dark',
        color: 'background',
        padding: 2,
        variant: 'variants.footer',
        a: {
          textDecoration: 'none',
          color: 'background',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        p: {
          fontSize: 1,
        },
      }}
      id="footer"
    >
      <Flex
        as="section"
        sx={{
          display: 'flex',
          flexFlow: 'wrap',
          justifyContent: 'space-evenly',
          py: 2,
          px: 3,
        }}
      >
        <FooterMainItem className="footer__addr">
          <Heading>Join Us</Heading>
          {/* <SiteBranding sx={{ ml: 0 }} /> */}

          <FooterHeading>Services</FooterHeading>
          <p>
            Sunday - 10am <br />
            Wednesday - Times and locations vary, please click the link below.
            <br />
          </p>
          <p sx={{ a: { color: 'secondary' } }}>
            For more information, see our{' '}
            <Link to="/service-times">service times page.</Link>{' '}
          </p>
          <FooterHeading>Address</FooterHeading>
          <p>
            {address.streetNo} {address.street} <br />
            {address.city}, {address.state} {address.zip} <br />
          </p>
        </FooterMainItem>

        <FooterMainItem>
          <Heading>Contact</Heading>
          <FooterHeading>Phone</FooterHeading>
          <p>
            <Styled.a
              href={`tel:+1${metaData.sanityData.church_number.replace(
                /-/g,
                ''
              )}`}
            >
              {metaData.sanityData.church_number}
            </Styled.a>
          </p>
          <FooterHeading>Email</FooterHeading>

          <p>
            <Styled.a href={`mailto:${metaData.sanityData.church_email}`}>
              {metaData.sanityData.church_email}
            </Styled.a>
          </p>
        </FooterMainItem>
        <FooterMainItem>
          <Heading>Online</Heading>
          <FooterHeading>Social</FooterHeading>
          <Box sx={{ pt: 2, ml: '-8px' }}>
            <IconButton aria-label="Link to our FaceBook Page">
              <Styled.a href={metaData.facebookLink} target="_blank">
                <Facebook />
              </Styled.a>
            </IconButton>
            <IconButton aria-label="Check us out on Instagram">
              <Styled.a href={metaData.instagramLink} target="_blank">
                <Instagram />
              </Styled.a>
            </IconButton>
            <IconButton aria-label="Follow us on Twitter">
              <Styled.a href={metaData.twitterLink} target="_blank">
                <Twitter />
              </Styled.a>
            </IconButton>
            <IconButton aria-label="Watch on YouTube">
              <Styled.a href={metaData.youtubeLink} target="_blank">
                <Youtube />
              </Styled.a>
            </IconButton>
          </Box>
        </FooterMainItem>
      </Flex>

      <Styled.div
        as="section"
        // * ft-legal
      >
        <ul
          sx={{
            fontSize: 0,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            'li:nth-of-type(1)': { flexGrow: '1' },
          }}
        >
          <LegalFooterLi>
            &copy; {new Date().getFullYear()} {metaData.sanityData.church_name}.
            All rights reserved.
          </LegalFooterLi>

          <LegalFooterLi>
            Made with <span className="heart">♥</span> remotely by Grasty Tech.
          </LegalFooterLi>
        </ul>
      </Styled.div>
    </footer>
  );
};

export default SiteFooter;
