/** @jsx jsx */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { jsx, useThemeUI, Text } from 'theme-ui';
import { ReflexLink as Link } from './reflex-link';
import { NavContext } from '../../../utils';

export const NavMenuLink = ({
  title,
  link,
  kind,
  route,
  isOpen,
  dropdown,
  depth,
  ...props
}) => {
  const activeStyle = {
    color: 'primary',
  };

  props = {
    ...props,
  };

  const [isNavOpen, setIsNavOpen] = useContext(NavContext);

  const linkStyle = {};

  const finalLink = inputLink => {
    if (route) {
      const readyLink = `/${route.slug.current}`;
      return readyLink;
    }
    const readyLink = inputLink;
    return readyLink;
  };
  const readyLinkfromFunction = finalLink(link);

  return kind === 'link' ? (
    <Link
      {...(isOpen && activeStyle)}
      sx={{
        textAlign: ['left', null, 'center'],
        textDecoration: 'none',
        px: ['80px', null, 2],
        py: dropdown ? 1 : null,
        display: 'block',
        width: '100%',
        color: 'header.text',
        variant: 'variants.navLink',
        ':hover': {
          color: 'secondary',
        },
        '.active': {
          fontWeight: 'bold',
          color: 'primary',
        },
      }}
      href={readyLinkfromFunction}
      activeStyle={activeStyle}
      {...linkStyle}
      {...props}
      onClick={() => setIsNavOpen(false)}
    >
      {title}
    </Link>
  ) : (
    <Text
      sx={{
        textAlign: ['left', null, 'center'],
        textDecoration: 'none',
        px: ['80px', null, 2],
        py: dropdown ? 1 : null,
        display: 'block',
        width: '100%',
        color: 'header.text',
        variant: 'variants.navLink',
        ':hover': {
          // color: 'primary',
        },
        '.active': {
          fontWeight: 'bold',
          color: 'primary',
        },
      }}
      {...props}
    >
      {title}
    </Text>
  );
};
