import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SiteMetaData {
        logo: file(name: { eq: "site-logo" }) {
          # childImageSharp {
          #   fluid(maxHeight: 512) {
          #     ...GatsbyImageSharpFluid_withWebp
          #   }
          # }
          publicURL
        }
        seoImage: file(name: { eq: "site-social" }) {
          childImageSharp {
            resize(width: 1024) {
              src
              width
              height
            }
          }
        }
        sanityConfig {
          address {
            city
            state
            street
            streetNo
            zip
          }
          church_email
          church_name
          church_number
          social {
            facebook
            instagram
            twitter
            youtube
          }
        }
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
            menuLinks {
              name
              link
              type
              subMenu {
                link
                name
                type
              }
            }
            socialLinks {
              name
              link
              location
            }
            displaySiteLogo
            displaySiteTitle
            displaySiteLogoMobile
            displaySiteTitleMobile
            invertSiteLogo
          }
        }
      }
    `
  );

  const { logo } = data;
  const seoImage = data.seoImage.childImageSharp.resize;
  const metaData = data.site.siteMetadata;
  const sanityData = data.sanityConfig;
  const facebookLink = `https://facebook.com/${sanityData.social.facebook}`;
  const instagramLink = `https://instagram.com/${sanityData.social.instagram}`;
  const twitterLink = `https://twitter.com/${sanityData.social.twitter}`;
  const youtubeLink = `https://youtube.com/${sanityData.social.youtube}`;

  // From Catalyst implementation
  // const twitterLink = data.site.siteMetadata.socialLinks
  //   .filter(social => social.name.toLowerCase() === 'twitter')
  //   .map(social => social.link);
  // const twitterUsername = twitterLink.length
  //   ? twitterLink[0]
  //       .toString()
  //       .toLowerCase()
  //       .replace('https://www.twitter.com/' && 'https://twitter.com/', '@')
  //   : 'Unknown';

  const allData = {
    ...metaData,
    sanityData,
    logo,
    seoImage,
    // twitterUsername,
    facebookLink,
    instagramLink,
    twitterLink,
    youtubeLink,
  };
  return allData;
};
