// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sermon-series-index-js": () => import("./../../../src/pages/sermon-series/index.js" /* webpackChunkName: "component---src-pages-sermon-series-index-js" */),
  "component---src-pages-sermon-series-sanity-series-slug-current-js": () => import("./../../../src/pages/sermon-series/{SanitySeries.slug__current}.js" /* webpackChunkName: "component---src-pages-sermon-series-sanity-series-slug-current-js" */),
  "component---src-pages-sermons-index-js": () => import("./../../../src/pages/sermons/index.js" /* webpackChunkName: "component---src-pages-sermons-index-js" */),
  "component---src-pages-sermons-sanity-sermon-slug-current-js": () => import("./../../../src/pages/sermons/{SanitySermon.slug__current}.js" /* webpackChunkName: "component---src-pages-sermons-sanity-sermon-slug-current-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

