/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/prop-types */
/** @jsx jsx */
import { jsx, Link as ThemeLink } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import { isRelativeUrl } from '../../../utils';

export const ReflexLink = ({ to, href, activeStyle, ...props }) => {
  const url = to || href;

  if (typeof url !== 'string') return null;

  return isRelativeUrl(url) ? (
    <ThemeLink
      as={GatsbyLink}
      to={url}
      {...props}
      sx={{
        '&[aria-current=page]': activeStyle,
      }}
    />
  ) : (
    <ThemeLink href={url} {...props} />
  );
};
