/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const SiteMain = ({ children }) => (
  <main
    sx={{
      mt: 0,
      mb: 0,
      gridArea: 'main',
      variant: 'variants.main',
    }}
  >
    {children}
  </main>
);

SiteMain.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteMain;
