/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx, useThemeUI, Box, Flex, IconButton, Styled } from 'theme-ui';
import { useContext, useEffect } from 'react';
// import { useCatalystConfig } from "gatsby-theme-catalyst-core"
import { Menu, X } from 'react-feather';
import Branding from './branding/branding';
import { NavMenu } from './navbar/reflex-navmenu';
import { NavContext, useWindowWidth } from '../../utils';
// import { Icon } from './navbar/reflex-src/icon';

// todo
// * Decide on configurability
// * Add text colors to theme-ui based on menu open status

const SiteHeader = () => {
  const [isNavOpen, setIsNavOpen] = useContext(NavContext);
  const { theme } = useThemeUI();
  // const width = useWindowWidth();
  // useEffect(() => {
  //   if (width >= parseInt(theme.breakpoints[1], 10) && isNavOpen) {
  //     setIsNavOpen(false);
  //     console.log('I set it to false. Yeah me!');
  //   }
  // });

  return (
    <header
      sx={{
        position: ['sticky', 'relative'],
        top: 0,
        width: '100%',
        zIndex: '1000', // Ensure the header is always on top
        boxShadow: '0 1px 0 rgba(0,0,0,.1)',
        // fontFamily: 'heading',
        variant: 'variants.header',
        bg: 'background',
      }}
      id="header"
    >
      <Box
        sx={{
          mx: 'auto',
          px: 3, // [4, 6, 6, 4]
        }}
      >
        <Flex
          //  * Full size header
          sx={{
            display: ['none', null, 'flex'],
            py: 2,
            justifyContent: 'space-between',
          }}
        >
          <Branding />
          <NavMenu />
        </Flex>
        <Flex
          // * Mobile header
          sx={{
            display: ['flex', null, 'none'],
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Flex
            // * Masthead when Nav is closed
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              pl: 1,
              pr: 1,
              visibility: isNavOpen ? 'hidden' : 'visible',
              transition: 'all .15s ease-in',
            }}
          >
            <Branding />
            <IconButton size={60} onClick={() => setIsNavOpen(true)}>
              <Menu />
            </IconButton>
          </Flex>
          <Box
            // * Nav
            sx={{
              position: 'absolute',
              zIndex: '1000',
              bg: 'background',
              top: 1,
              left: 1,
              right: 1,
              px: 1,
              borderRadius: 'xl',
              overflow: 'scroll',
              boxShadow: '3xl',
              border: '1px solid',
              borderColor: 'border',
              transform: isNavOpen ? 'scale(1)' : 'scale(0.95)',
              visibility: isNavOpen ? 'visible' : 'hidden',
              opacity: isNavOpen ? 1 : 0,
              transition: 'all .25s ease-in',
              transformOrigin: '100% 0',
              maxHeight: '95vh',
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                width: '100%',
                py: 2,
                px: 0,
              }}
            >
              <Branding />
              <IconButton size={60} onClick={() => setIsNavOpen(false)}>
                <X />
              </IconButton>
            </Flex>
            <NavMenu />
          </Box>
        </Flex>
      </Box>
    </header>
  );
};

export default SiteHeader;

// Header from catalyst-core from shadowing

// const SiteHeader = () => (
//   <header
//     sx={{
//       bg: '#ddd',
//       p: 3,
//       gridArea: 'header',
//       variant: 'variants.header',
//     }}
//     id="header"
//   >
//     <p>Header area for latent shadowing in sibling themes</p>
//   </header>
// )

// export default SiteHeader

// Header from stock gatsby-starter

// import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
// import React from 'react'
// import { Styled } from 'theme-ui'

// const Header = ({ siteTitle }) => (
//   <header
//     style={{
//       background: `rebeccapurple`,
//       marginBottom: `1.45rem`,
//     }}
//   >
//     <div
//       style={{
//         margin: `0 auto`,
//         maxWidth: 960,
//         padding: `1.45rem 1.0875rem`,
//       }}
//     >
//       <Styled.h1 style={{ margin: 0 }}>
//         <Link
//           to="/"
//           style={{
//             color: `white`,
//             textDecoration: `none`,
//           }}
//         >
//           {siteTitle}
//         </Link>
//       </Styled.h1>
//     </div>
//   </header>
// )

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

// export default Header
