/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
// import { useEffect } from "react"
import { Helmet } from 'react-helmet';
import GlobalCSS from '../utils/global-css';
import SiteContainer from './site-container';
import Header from './header/header';
import Main from './main';
// import ContentContainer from './content-container';
import Footer from './footer';
import { SEO } from '../utils';
// import { useLocation } from "@reach/router"
// import { HomeContext } from "gatsby-theme-catalyst-core"
// import { useContext } from "react"

const GlobalLayout = ({ children }) => (
  //   const [isHome, setIsHome] = useContext(HomeContext) // eslint-disable-line
  //   const location = useLocation()
  //   const home = location.pathname === "/"
  //   useEffect(() => {
  //     if (home) {
  //       setIsHome(true)
  //     } else {
  //       setIsHome(false)
  //     }
  //   }, []) // eslint-disable-line
  <Styled.root>
    <SEO title="" />
    <Helmet>
      <script
        type="text/javascript"
        src="https://livebar.church/livebar.js"
        data-layout="header"
        data-background-color="#2D3142"
        data-button-color="#EF8354"
        data-text-color="#ffffff"
        data-button-text-color="#ffffff"
        data-button-text="Watch Live in"
        data-header-text="Join us online this Sunday live at 10AM!"
        data-service-1-day-of-week="0"
        data-service-1-hours="10"
        data-service-1-minutes="0"
        data-service-1-duration-minutes="40"
        data-dismissable="no"
        data-live-url="https://vbcscotialive.freeonlinechurch.com/"
        data-timezone="America/New_York"
        data-load-livebar-immediately="yes"
      />
    </Helmet>
    <GlobalCSS />
    <SiteContainer>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </SiteContainer>
  </Styled.root>
);

export default GlobalLayout;
