/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useContext } from 'react';
// import {
//   NavContext,
//   useCatalystConfig,
//   useSiteMetadata,
// } from 'gatsby-theme-catalyst-core'
import { Link } from 'gatsby';
import { useSiteMetadata, NavContext } from '../../../utils';
import siteLogo from '../../../images/site-logo.svg';

const SiteLogo = () => {
  const [isNavOpen, setIsNavOpen] = useContext(NavContext);
  const { title } = useSiteMetadata();

  // const invertLogo = () => {
  //   if (invertSiteLogo) {
  //     return 'invert(1)';
  //   }
  //   return 'none';
  // };

  return (
    <Link to="/" onClick={() => setIsNavOpen(false)}>
      <img
        sx={{
          display: 'block',
          height: '100%',
          width: [200, 225, 250],
          mr: [0, 0, 2],
          objectFit: 'contain',
        }}
        src={siteLogo}
        alt={title}
      />
    </Link>
  );
};

export default SiteLogo;
