/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const SiteContainer = ({ children }) => (
  <div
    sx={{
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      variant: 'variants.siteContainer',
    }}
  >
    {children}
  </div>
);

SiteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteContainer;
