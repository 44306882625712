import { useStaticQuery, graphql } from 'gatsby';

export const useNavMenu = () => {
  const { sanityMain } = useStaticQuery(
    graphql`
      query NavMenu {
        sanityMain {
          navigationMenu {
            items {
              ... on SanityMenuItem {
                title
                internalPageRoute {
                  ... on SanityPage {
                    slug {
                      current
                    }
                  }
                }
                link
                kind
              }
              ... on SanityMenuBranch {
                title
                slug {
                  current
                }
                menuItem {
                  title
                }
                submenuItems {
                  title
                  link
                  kind
                  internalPageRoute {
                    ... on SanityPage {
                      title
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return sanityMain.navigationMenu;
};
