import { base } from '@theme-ui/presets';
import { toTheme } from '@theme-ui/typography';
import sutro from 'typography-theme-sutro';
import merge from 'deepmerge';

export const baseColors = {
  darkgrey: '#2D3142', // Gunmetal
  meddarkgrey: '#616d7d',
  lightergrey: '#4F6A8F', // Med Slate Blue
  brighterblue: 'blue',
  orange: '#EF8354', // Mandarin
  mutedgrey: '#BFC0C0', // Silver sand
  navbggrey: '#f9f9fa',
};

export const baseOverrides = {
  ...base,
  colors: {
    text: '#000',
    background: '#fff',
    primary: baseColors.lightergrey,
    secondary: baseColors.orange,
    muted: baseColors.mutedgrey,
    navbggrey: baseColors.navbggrey,
    dark: baseColors.darkgrey,
    meddark: baseColors.meddarkgrey,
    header: {
      background: '#fff',
      backgroundOpen: baseColors.navbggrey,
      text: '#000',
      textOpen: baseColors.darkgrey,
      icons: baseColors.darkgrey,
      iconsOpen: baseColors.lightergrey,
    },
    border: '#ebece9',
    borderHover: '#b9bdb3',
  },
  fonts: {
    ...base.fonts,
    fancyFont: "'Abril Fatface', cursive",
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '3px solid',
    '4px solid',
    '5px solid',
    '6px solid',
    '7px solid',
    '8px solid',
    '9px solid',
    '10px solid',
  ],
  radii: {
    none: '0',
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.375rem',
    xl: '0.5rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    '4xl': '2rem',
    full: '9999px',
  },
  shadows: {
    none: 'none',
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    lg: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    xl:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    '2xl':
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    xlcomplete:
      '0px 0px 15px 2px rgba(0, 0, 0, 0.1), 0px 0px 6px -2px rgba(0, 0, 0, 0.05)',
  },
  styles: {
    ...base.styles,
    root: {
      backgroundColor: 'background',
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 2,
    },
    a: {
      ...base.styles.a,
      textDecoration: 'none',
    },
  },
  sizes: {
    ...base.space,
    maxContentWidth: '850px', // Sets the container size on larger screens, e.g. tablets and laptops
    maxPageWidth: '2840px', // Sets the max width of elements like the header/footer large screens
    headerHeight: '70px',
  },
  variants: {
    header: {
      fontFamily: 'heading',
      fontSize: 1,
    },
    footer: {
      ul: {
        listStyle: 'none',
      },
    },
  },
  breakpoints: ['640px', '813px', '1024px', '1280px'],
};
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const theme = merge(baseOverrides, toTheme(sutro), {
  arrayMerge: overwriteMerge,
});

export default theme;
