import React from 'react';
import GlobalLayout from './src/components/globalLayout';
import { NavProvider } from './src/utils';

export const wrapPageElement = ({ element, props }) => (
  <GlobalLayout {...props}>{element}</GlobalLayout>
);

export const wrapRootElement = ({ element }) => (
  <NavProvider>{element}</NavProvider>
);
