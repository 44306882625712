/** @jsx jsx */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import { Styled, jsx } from 'theme-ui';
import { NavMenuItem as ReflexNavMenuItem } from './reflex-navmenu-item';
import { useNavMenu } from './reflex-src/use-nav-hook';

export const NavMenu = ({
  dropdown = true,
  trigger = 'hover',
  vertical = false,
  expand = true,
  ...props
}) => {
  const nav = useNavMenu();

  if (!nav || !nav.items?.length) return null;

  return (
    <Styled.ul
      sx={{
        display: 'flex',
        listStyle: 'none',
        p: 0,
        my: 0,
        mx: [-4, null, 0], // From reflex but based on grid
        flexDirection: ['column', null, 'row', null, null],
        flexWrap: 'wrap',
        textAlign: ['left', null, 'left', null, null],
        borderTop: [1, null, 0],
        borderColor: ['muted', null],
      }}
      {...props}
    >
      {nav.items.map((item, index) => (
        <ReflexNavMenuItem
          key={index}
          dropdown={dropdown}
          trigger={trigger}
          vertical={vertical}
          expand={expand}
          {...item}
        />
      ))}
    </Styled.ul>
  );
};
